import { BoltRounded, SwapHoriz } from '@mui/icons-material';
import { Button, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import React from 'react';
import { useMemo } from 'react';
import { NumericFormat } from 'react-number-format';
import { useUpdatePreviewItemRecommendationMutation } from '../../../../api/campaignSlice';

function PreviewOptimization(props) {
    const theme = useTheme();
    const [updateRecommendation] = useUpdatePreviewItemRecommendationMutation();
    var headerText = '';

    if (props.recommendation.type === 'removal') {
        headerText = 'Remove This Item';
    } else if (props.recommendation.type === 'update') {
        headerText = 'Update This Item';
    } else if (props.recommendation.type === 'addition') {
        headerText = 'Add This Item';
    } else if (props.recommendation.type === 'swap') {
        headerText = 'Swap This Item';
    } else if (props.recommendation.type === 'compliance') {
        headerText = 'Compliance Opportunity';
    }

    const isExcluded = useMemo(() => {
        return props.recommendation.is_excluded;
    }, [props.recommendation]);

    const handleExcludeRecommendation = async() => {
        if (isExcluded) {
            await updateRecommendation({
                card: props.card,
                recommendation: props.recommendation,
                isExcluded: false
            });
        } else {
            await updateRecommendation({
                card: props.card,
                recommendation: props.recommendation,
                isExcluded: true
            });
        }
    }

    return (
        <Stack 
            direction='row' 
            spacing={2} 
            backgroundColor={isExcluded ? theme.palette.background.categoryDrawer : '#79c5e850' }
            sx={{ 
                '&:last-child': { borderRadius: '0 0 8px 8px' },
            }}
        >
            <Stack px={2} py={1} justifyContent='space-between' direction='row' alignItems='center' sx={{ width: '100%', borderRadius : 'inherit'  }}>
                <Stack width='65%' spacing={3} direction='row' alignItems='center'>
                    <Stack width='20%' direction='row' alignItems='center'>
                        <BoltRounded size='small' style={{ 
                            marginLeft: '-5px', 
                            color: isExcluded ? 'black' : `${theme.palette.blue.main}` 
                        }} 
                        />
                        <Stack>
                            <Typography noWrap variant='normal' sx={{ fontSize: '16px' }}>
                                {headerText}
                            </Typography>
                            <Typography noWrap variant='normal' sx={{ fontSize: '12px', color: `${theme.palette.subtext.main}` }}>
                                Source: {props.recommendation.source === 'time_series' ? 'Time Series' : 'Peer Comparison'}
                            </Typography>
                        </Stack>
                    </Stack>
                    <Stack>
                        <Typography variant='normal' sx={{ fontSize: '14px' }}>
                            {props.recommendation.item.name ? props.recommendation.item.name : props.recommendation.item.external_name}
                        </Typography>
                        <Typography variant='normal' sx={{ fontSize: '12px', color: `${theme.palette.subtext.main}` }}>
                            {props.recommendation.item.vendor.name + ' - ' + props.recommendation.item.part_number}
                        </Typography>
                    </Stack>
                    
                    { (props.recommendation.type === 'swap' || props.recommendation.type === 'compliance') && 
                        <>
                            <SwapHoriz />
                            <Stack>
                                <Typography variant='normal' sx={{ fontSize: '14px' }}>
                                    {props.recommendation.recommended_item.name ? props.recommendation.recommended_item.name : props.recommendation.recommended_item.external_name}
                                </Typography>
                                <Typography variant='normal' sx={{ fontSize: '12px', color: `${theme.palette.subtext.main}` }}>
                                    {props.recommendation.recommended_item.vendor.name + ' - ' + props.recommendation.recommended_item.part_number}
                                </Typography>
                            </Stack>
                        </>
                    }
                </Stack>
                <Stack alignItems='center'>
                    <Typography variant='bold' sx={{ fontSize: '14px' }}>
                        Estimated Savings: <NumericFormat
                            value={props.recommendation.recommendation_value / 100}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'$'}
                            decimalScale={2}
                            fixedDecimalScale={true}
                        />
                    </Typography>
                    <Button variant='contained' size='small' onClick={handleExcludeRecommendation}>
                        { isExcluded ? 'Remove Manual Exclusion' : 'Set Manual Exclusion' }
                    </Button>
                </Stack>
            </Stack>
        </Stack>
    )
}

export default PreviewOptimization;

