import React, { useState } from 'react';
import { Paper, Stack, Typography, Checkbox, Tooltip, ButtonGroup, Button } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';


function AdditionalSettings(props) {
    const [maxRecs, setMaxRecs] = useState(props.campaign.settings.max_recs_per_card ?? 15);

    const handleCaseCountChange = (value) => {
        setMaxRecs(value);
        props.setSettings({ ...props.settings, max_recs_per_card: value });
    };

    return (
        <Paper elevation={2} sx={{ width: '23%' }}>
            <Stack p={3} spacing={2} height='100%'>
                <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='light' sx={{ fontSize: '18px' }}>Additional Settings</Typography>
                    <Tooltip title='Optimization types are used to determine which types of optimizations will be shown to physicians.'>
                        <InfoOutlined style={{ opacity: 0.5 }} />
                    </Tooltip>
                </Stack>
                <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='normal' sx={{ fontSize: '16px' }}>Exclude 0/0 Items</Typography>
                    <Checkbox disabled={props.disabled} checked={props.settings.exclude_zero_quantity_items ?? false} onChange={(e) => props.setSettings({ ...props.settings, exclude_zero_quantity_items: !props.settings.exclude_zero_quantity_items ?? e.target.checked})} />
                </Stack>
                <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='normal' sx={{ fontSize: '16px' }}>Exclude ALL Quantity Increases</Typography>
                    <Checkbox disabled={props.disabled} checked={props.settings.exclude_all_quantity_increases ?? false} onChange={(e) => props.setSettings({ ...props.settings, exclude_all_quantity_increases: !props.settings.exclude_all_quantity_increases ?? e.target.checked })} />
                </Stack>
                <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='normal' sx={{ fontSize: '16px' }}>Exclude Open Increases</Typography>
                    <Checkbox disabled={props.disabled} checked={props.settings.exclude_open_increases ?? false} onChange={(e) => props.setSettings({ ...props.settings, exclude_open_increases: !props.settings.exclude_open_increases ?? e.target.checked })} />
                </Stack>
                <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='normal' sx={{ fontSize: '16px' }}>Exclude Recent Rejections</Typography>
                    <Checkbox disabled={props.disabled} checked={props.settings.exclude_recently_rejected_recs ?? false} onChange={(e) => props.setSettings({ ...props.settings, exclude_recently_rejected_recs: !props.settings.exclude_recently_rejected_recs ?? e.target.checked })} />
                </Stack>
                <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Stack direction='row' alignItems= 'center' spacing={2}>
                        <Typography variant='normal' sx={{ fontSize: '16px' }}>Max Recs / Card: </Typography>
                        <Typography variant='normal' sx={{ fontSize: '16px' }}>{maxRecs}</Typography>
                    </Stack>
                    <ButtonGroup disabled={props.disabled} variant='outlined'>
                        <Button disabled={maxRecs === 0} onClick={() => handleCaseCountChange(maxRecs - 1)}>-</Button>
                        <Button onClick={() => handleCaseCountChange(maxRecs + 1)}>+</Button>
                    </ButtonGroup>
                </Stack>
            </Stack>
        </Paper>
    )
}

export default AdditionalSettings;