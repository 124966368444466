import { Stack } from '@mui/material';
import React from 'react';
import OptimizationContextBullet from './OptimizationContextBullet';
import OptimizationContextBenefit from './OptimizationContextBenefit';

function OptimizationContext(props) {
    const context = props.recommendation.context;
    const recType = props.recommendation.type;
    const isQuantityIncrease = recType === 'update' && (props.item.open_quantity + props.item.hold_quantity) < (props.recommendation.open_quantity + props.recommendation.hold_quantity);
    const isQuantityDecrease = recType === 'update' && (props.item.open_quantity + props.item.hold_quantity) > (props.recommendation.open_quantity + props.recommendation.hold_quantity);

    function shouldShowDownTrendingUsage() {
        if (context['predicted_usage'] === undefined) return false;

        return props.recommendation.source === 'time_series' && (
            (recType === 'removal') ||
            (isQuantityDecrease && context['predicted_usage'] < props.item.open_quantity + props.item.hold_quantity)
        );
    }

    function shouldShowUpTrendingUsage() {
        if (context['predicted_usage'] === undefined) return false;

        return recType === 'addition' || 
            (isQuantityIncrease && context['predicted_usage'] > props.item.open_quantity + props.item.hold_quantity);
    }

    function shouldShowHighUsagePercentage() {
        if (context['usage_percentage'] === undefined) return false;
        return recType === 'addition' && context['usage_percentage'] >= 0.50;
    } 

    function shouldShowLowUsagePercentage() {
        if (context['usage_percentage'] === undefined) return false;

        return (recType === 'removal' && context['usage_percentage'] <= 0.45);
    }

    function shouldShowLowUtilization() {
        if (context['low_utilization'] === undefined) return false;

        return true;
    }

    function shouldShowCommonPeerUsageItem() {
        if (!context['procedure_prevalence']) return false;
        return (recType === 'addition' && context['procedure_prevalence'] >= 0.66);
    }

    function shouldShowLowProcedurePrevalence() {
        if (!context['procedure_prevalence']) return false;
        return (recType === 'removal' && context['procedure_prevalence'] <= 0.2) || 
            (isQuantityDecrease && context['procedure_prevalence'] <= 0.2);
    }

    function shouldShowAverageUsage() {
        if (context['average_usage'] === undefined) return false;
        return ((isQuantityIncrease && context['average_usage'] > (props.item.open_quantity + props.item.hold_quantity)) || recType === 'addition');
    }
    
    function shouldShowElevatedCategorySpend() {
        if (
            context['average_factored_category_spend'] === undefined ||
            context['surgeon_factored_category_spend'] === undefined ||
            context['standard_deviation_category_spend'] === undefined
        ) return false;
        if (props.recommendation.type === 'removal' || isQuantityDecrease) {
            return context['surgeon_factored_category_spend'] - context['average_factored_category_spend'] > context['standard_deviation_category_spend'];
        }
        return false;
    }

    function shouldShowInfrequentPeerUsage() {
        if (context['peer_usage_percentile_75'] === undefined) return false;
        return props.recommendation.source === 'card_comparison' && props.recommendation.type === 'removal';
    }

    function shouldShowUniqueItemFlag() {
        return context['unique_item_flag'] === true;
    }

    function shouldShowOutlierItemFlag() {
        return context['outlier_item_flag'] === true && !context['procedure_prevalence'];
    }

    function shouldShowLowTypicalUsage() {
        return isQuantityDecrease && props.recommendation.source === 'time_series';
    }

    function shouldShowComparisonExcessQuantity() {
        return props.recommendation.source === 'card_comparison' && isQuantityDecrease && context['median_procedure_usage'] !== undefined;
    }

    function shouldShowCurrentOpenUsagePercentage() {
        return props.recommendation.source === 'card_comparison' && 
            (props.recommendation.open_quantity < props.item.open_quantity) && 
            context['current_open_usage_percentage'] !== undefined;
    }

    function shouldShowComparisonLowTypicalUsage() {
        return props.recommendation.source === 'card_comparison' && isQuantityDecrease && context['peer_usage_percentile_75'] !== undefined;
    }

    function shouldShowComparisonInsufficientCardQuantity() {
        return props.recommendation.source === 'card_comparison' && isQuantityIncrease;
    }

    return (
        <Stack spacing={1} py={1.5}>
            { shouldShowDownTrendingUsage() &&
                <OptimizationContextBullet type='excess_card_quantity_ts' />
            }
            { shouldShowUpTrendingUsage() &&
                <OptimizationContextBullet type='insufficient_card_quantity_ts' />
            }
            { shouldShowHighUsagePercentage() &&
                <OptimizationContextBullet type='high_utilization' data={context['usage_percentage']} />
            }
            { shouldShowLowUsagePercentage() &&
                <OptimizationContextBullet type='low_utilization' data={context['usage_percentage']} />
            }
            { shouldShowLowUtilization() &&
                <OptimizationContextBullet type='low_utilization' data={context['low_utilization']} />
            }
            { shouldShowCommonPeerUsageItem() &&
                <OptimizationContextBullet type='common_peer_usage' data={context['procedure_prevalence']} />
            }
            { shouldShowLowProcedurePrevalence() &&
                <OptimizationContextBullet type='low_procedure_prevalence' data={context['procedure_prevalence']} />
            }
            { shouldShowAverageUsage() &&
                <OptimizationContextBullet type='high_average_usage' data={{ usage: context['average_usage'], quantity: (props.item?.open_quantity + props.item?.hold_quantity || 0) }} />
            }
            { shouldShowElevatedCategorySpend() &&
                <OptimizationContextBullet type='elevated_category_spend' data={{
                    value: (context['surgeon_factored_category_spend'] - context['average_factored_category_spend']) / context['average_factored_category_spend'], 
                    category: props.item.category 
                }} />
            }
            { shouldShowInfrequentPeerUsage() && 
                <OptimizationContextBullet type='infrequent_peer_usage' />
            }
            { shouldShowUniqueItemFlag() && 
                <OptimizationContextBullet type='unique_item' />
            }
            { shouldShowOutlierItemFlag() && !shouldShowUniqueItemFlag() &&
                <OptimizationContextBullet type='outlier_item' data={context['procedure_prevalence']} />
            }
            { shouldShowLowTypicalUsage() &&
                <OptimizationContextBullet type='low_typical_usage_ts' data={{ value: context['percentile_80'], new_quantity: (props.recommendation.open_quantity + props.recommendation.hold_quantity) }} />
            }
            { shouldShowComparisonExcessQuantity() &&
                <OptimizationContextBullet type='excess_card_quantity_cc' data={{ value: context['median_procedure_usage'], old_quantity: (props.item.open_quantity + props.item.hold_quantity) }} />
            }
            { shouldShowCurrentOpenUsagePercentage() &&
                <OptimizationContextBullet type='current_open_usage_percentage' data={{ value: 1.0 - context['current_open_usage_percentage'], current_open: props.item.open_quantity }} />
            }
            { shouldShowComparisonLowTypicalUsage() && !shouldShowCurrentOpenUsagePercentage() &&
                <OptimizationContextBullet type='low_typical_usage_cc' data={{ value: context['peer_usage_percentile_75'], new_quantity: (props.recommendation.open_quantity + props.recommendation.hold_quantity) }} />
            }
            { shouldShowComparisonInsufficientCardQuantity() &&
                <OptimizationContextBullet type='insufficient_card_quantity_cc' data={{ value: context['peer_usage_percentile_75'], old_quantity: (props.item.open_quantity + props.item.hold_quantity) }} />
            }
            { !props.excludeBenefit &&
                <OptimizationContextBenefit recommendation={props.recommendation} item={props.item} />
            }
        </Stack>
    )
}

export default OptimizationContext;
