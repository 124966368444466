import React, { useMemo } from 'react';
import { Stack, Typography, Divider } from '@mui/material';
import StatisticTile from '../../StatisticTiles/StatisticTile';
import { useParams } from 'react-router-dom';
import { useGetCampaignQuery } from '../../../../api/campaignSlice';
import PreviewOptimization from './PreviewOptimization';
import ItemEntry from './ItemEntry';
import { useGetCardQuery } from '../../../../api/cardSlice';

function DraftPreview() {
    const {campaignId, cardId}  = useParams();

    const { data: campaign = {} } = useGetCampaignQuery({ campaignId: campaignId });
    const { data: cardData = {} } = useGetCardQuery(cardId);

    const getAssociatedRecommendations = (item) => {
        return cardData.recommendations?.filter((rec) => rec.item.id === item.id);
    };

    const nonExcludedRecommendations = useMemo(() => {
        if (!cardData.recommendations || cardData?.is_excluded) { return [] }

        var recsToReturn = [];
        recsToReturn = recsToReturn.concat(cardData.recommendations?.filter((rec) => {
            return !rec.is_excluded;
        }));
        return recsToReturn;
    }, [cardData, campaign]);

    const maxSavings = useMemo(() => {
        if (cardData.is_excluded) { return 0; }
        return nonExcludedRecommendations.reduce((total, rec) => {
            return total + rec.recommendation_value;
        }, 0);
    }, [cardData, nonExcludedRecommendations]);

    return (
        <Stack spacing={2} px={{ xs: 3, md: 5 }} display='flex' height='100%' overflow='auto' >
            <Stack direction="row" justifyContent="space-between" alignItems='center'>
                <Stack width='70%'>
                    <Typography variant='heavy' sx={{ fontSize: '20px', letterSpacing: '0.18px' , fontWeight:'700'}}>
                        Preview of Optimizations for {cardData.name}
                    </Typography>
                    <Typography variant='normal' sx={{ fontSize: '16px' }}>
                        Dr. {cardData.owner?.first_name} {cardData.owner?.last_name}
                    </Typography>
                </Stack>
            </Stack>
            <Stack direction='row' spacing={2}>
                <StatisticTile title='Estimated Max. Savings (without exclusions)' value={maxSavings / 100} formatAsDollar />
                <StatisticTile title='Current Card Efficiency' value={cardData?.efficiency * 100} formatAsPercent />
                <StatisticTile title='Total Number of Optimizations (without exclusions)' value={nonExcludedRecommendations?.length} />
            </Stack>
            <Stack spacing={4} pb={4}>
                {Object.keys(cardData.pick_list ?? {}).map((category) => (
                    <Stack key={category} spacing={2}>
                        <Typography variant='heavy' sx={{ fontSize: '20px', letterSpacing: '0.18px' , fontWeight:'700'}}>
                            {category}
                        </Typography>
                        <Stack spacing={2} mt={1}>
                            {cardData.pick_list[category].map((item) => (
                                <Stack>
                                    <ItemEntry key={item.id} item={item} />
                                    <Stack mx={1} divider={<Divider orientation='horizontal' flexItem style={{ borderColor: 'rgba(0, 0, 0, 0.35)' }} />}>
                                        { getAssociatedRecommendations(item)?.map((rec) => (
                                            <PreviewOptimization 
                                                key={rec.id}
                                                recommendation={rec}
                                                card={cardData}
                                            />
                                        ))}
                                    </Stack>
                                </Stack>
                            ))}
                        </Stack>
                    </Stack>
                ))}
            </Stack>
        </Stack>
    );
}

export default DraftPreview;
