import React, { useMemo } from 'react';
import { Stack, Typography } from '@mui/material';
import PhysicianGroups from './PhysicianGroups';
import StaffReviewStatBox from './StaffReviewStatBox';
import { theme } from '../../../theme';
import { NumericFormat } from 'react-number-format';
import { useGetCompletedReviewsQuery, useGetUserStatisticsQuery } from '../../../api/staffReviewSlice';
import useStaffReviewUser from '../../Hooks/useStaffReviewUser';
import LoadingIndicator from '../../LoadingIndicator';

function CompletedReviews() {
    const { staffReviewUser: user } = useStaffReviewUser();
    const { data: pastReviews = [], isLoading } = useGetCompletedReviewsQuery(user.id, { skip: !user });
    const { data: userStats = {}, isSuccess } = useGetUserStatisticsQuery(user?.id, { skip: !user });

    const physicianDraftGroupings = useMemo(() => {
        const physicianData = {};
        pastReviews.forEach((draft) => {
            if (!physicianData[draft.card.doctor.id]) {
                physicianData[draft.card.doctor.id] = {
                    physician: draft.card.doctor,
                    drafts: [],
                };
            }
            physicianData[draft.card.doctor.id].drafts.push(draft);
        });
        return physicianData;
    }, [pastReviews]);

    if (isLoading) {
        return (
            <Stack px={{ xs: 2, md: 6 }} pb={3} sx={{ height: '100%', overflow: 'auto' }}>
                <Stack direction='row' justifyContent='space-between'>
                    <Typography variant='bold' alignSelf='end' sx={{ fontSize: '20px' }}>Physician Requests to Review</Typography>
                    <Stack textAlign='end'>
                        <Typography variant='heavy' sx={{ fontSize: '16px' }}>{user?.name}</Typography>
                        <Typography variant='normal' color={theme.palette.subtext.main} sx={{ fontSize: '14px' }}>{user?.hospital?.name}</Typography>
                    </Stack>
                </Stack>
                <Stack width="100%" height="100%">
                    <LoadingIndicator variant='reviews'/>
                </Stack>
            </Stack>
        );
    }

    return (
        <Stack px={4} py={2} spacing={2}>
            <Stack direction='row' justifyContent='space-between'>
                <Typography variant='bold' alignSelf='end' sx={{ fontSize: '20px' }}>Physician Requests to Review</Typography>
                <Stack textAlign='end'>
                    <Typography variant='heavy' sx={{ fontSize: '16px' }}>{user?.name}</Typography>
                    <Typography variant='normal' color={theme.palette.subtext.main} sx={{ fontSize: '14px' }}>{user?.hospital?.name}</Typography>
                </Stack>
            </Stack>
            <Stack direction='row' width='100%' sx={{
                border: '1px solid',
                borderRadius: '8px',
                borderColor: theme.palette.border.main,
            }}>
                <StaffReviewStatBox 
                    title='Your Total Optimizations Reviewed' 
                    value={userStats.total_optimizations_reviewed}
                    isLoaded={isSuccess}
                    sx={{ borderTopLeft: '16px', borderBottomLeft: '16px' }} 
                />
                <StaffReviewStatBox 
                    title='Your Total Approved' 
                    value={userStats.total_approved} 
                    isLoaded={isSuccess}
                />
                <StaffReviewStatBox 
                    title='Your Total Denied' 
                    value={userStats.total_denied} 
                    isLoaded={isSuccess}
                />
                <StaffReviewStatBox 
                    title='Average Review Time' 
                    value={userStats.average_review_time + ' days'} 
                    isLoaded={isSuccess}
                    tooltip='This represents the average time it takes from when an optimization is ready for your review to when the review is completed, across all optimizations assigned to you.'
                />
                <StaffReviewStatBox 
                    title='Your Total Estimated Savings Captured' 
                    value={
                        <NumericFormat value={userStats.total_savings_captured / 100} displayType={'text'} thousandSeparator={true} prefix={'$'} suffix={' / year'}/>
                    } 
                    isLoaded={isSuccess}
                    sx={{ borderTopRight: '16px', borderBottomRight: '16px' }} 
                    tooltip='This is the estimated annual impact of the preference card optimizations which you have approved.  This calculation is based on item cost, historical case counts per card and the item’s open / hold status.'
                />
            </Stack>
            <PhysicianGroups draftGroupings={physicianDraftGroupings} status='completed' />
        </Stack>
    );
}

export default CompletedReviews;

