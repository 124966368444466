import { apiSlice } from './apiSlice';
import { NotificationActions } from './notificationSlice';

export const cardSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getCardsForHospital: builder.query({
            query: ({ hospitalId, page }) => `/hospitals/${hospitalId}/cards${page ? `?page=${page}` : ''}`,
            providesTags: (result = [], error, arg) => 
                result.data 
                    ? [
                        ...result.data.map(({ id }) => ({ type: 'Card', id })),
                        { type: 'Card', id: 'PARTIAL-LIST' },
                    ]
                    : [{ type: 'Card', id: 'PARTIAL-LIST' }],
            async onQueryStarted({}, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch {
                    dispatch(NotificationActions.addErrorNotification({ message: 'Could not load cards at this time. Please try again later.' }));
                }
            },
            overrideExisting: true
        }),
        
        createCard: builder.mutation({
            query: ({cardName, cardOwner, hospitalId, copyTemplateId}) => ({
                url: `/hospitals/${hospitalId}/cardOwners/${cardOwner.id}/cards`,
                method: 'POST',
                body: {
                    name: cardName,
                    copy_template_id: copyTemplateId
                }
            }),
            async onQueryStarted({}, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    dispatch(NotificationActions.addSuccessNotification({ message: 'New card successfully created.' }));
                } 
                catch { 
                    dispatch(NotificationActions.addErrorNotification({ message: 'Failed to create card. Please try again later or contact support.' }));
                }
            },
            invalidatesTags: ['Card']
        }),

        updateCard: builder.mutation({
            query: ({ card, requestData }) => ({
                url: `/cards/${card.id}`,
                method: 'PATCH',
                body: requestData
            }),
            async onQueryStarted({ card, requestData }, { dispatch, queryFulfilled }) {
                try {
                    const { data: updatedCard } = await queryFulfilled;
                    dispatch (
                        apiSlice.util.updateQueryData('getCard', card.id.toString(), existingCard => {
                            Object.assign(existingCard, updatedCard);
                        })
                    );
                    dispatch(NotificationActions.addSuccessNotification({ message: 'Card successfully updated.' }));
                } catch {
                    dispatch(NotificationActions.addErrorNotification({ message: 'Could not update card at this time. Please try again later. '}));
                }
            }
        }),

        deleteCard: builder.mutation({
            query: ({ card }) => ({
                url: `/cards/${card.id}`,
                method: 'DELETE'
            }),
            async onQueryStarted({ card }, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    dispatch(NotificationActions.addSuccessNotification({ message: 'Card successfully deleted.' }));
                } catch {
                    dispatch(NotificationActions.addErrorNotification({ message: 'Could not delete card at this time. Please try again later.' }));
                }
            },
            invalidatesTags: ['Card']
        }),

        addAuxiliaryItem: builder.mutation({
            query: ({ card, auxiliaryItemData }) => ({
                url: `/cards/${card.id}/auxiliaryItems`,
                method: 'POST',
                body: auxiliaryItemData
            }),
            async onQueryStarted({ card, auxiliaryItemData }, { dispatch, queryFulfilled }) {
                try {
                    const { data: createdAuxItem } = await queryFulfilled;
                    dispatch (
                        apiSlice.util.updateQueryData('getCard', card.id.toString(), card => {
                            card.auxiliary_items.push(createdAuxItem);
                        })
                    )
                    dispatch(NotificationActions.addSuccessNotification({ message: `${createdAuxItem.category.name} created successfully.` }));
                } catch {
                    dispatch(NotificationActions.addErrorNotification({ message: 'Could not add new section at this time. Please try again later.' }));
                }
            }
        }),

        updateAuxiliaryItem: builder.mutation({
            query: ({ auxiliaryItem, newText }) => ({
                url: `/auxiliaryItems/${auxiliaryItem.id}`,
                method: 'PATCH',
                body: {
                    body: newText
                }
            }),
            async onQueryStarted({ auxiliaryItem, newText }, { dispatch, queryFulfilled }) {
                try {
                    const { data: updatedAuxItem } = await queryFulfilled;
                    dispatch (
                        apiSlice.util.updateQueryData('getCard', auxiliaryItem.card_id.toString(), card => {
                            let exisitingAuxItem = card.auxiliary_items.find(data => data.id === auxiliaryItem.id);
                            if (exisitingAuxItem) {
                                Object.assign(exisitingAuxItem, updatedAuxItem);
                            }
                        })
                    );
                    dispatch(NotificationActions.addSuccessNotification({ message: `${auxiliaryItem.category.name} updated successfully.` }));
                } catch {
                    dispatch(NotificationActions.addErrorNotification({ message: 'Could not update section at this time. Please try again later.' }));
                }
            }
        }),

        deleteAuxiliaryItem: builder.mutation({
            query: ({cardId,auxiliaryItemId}) =>({
                url: `cards/${cardId}/auxiliaryItems/${auxiliaryItemId}`,
                method: 'DELETE'
            }),
            async onQueryStarted({cardId,auxiliaryItemId}, {dispatch, queryFulfilled}){
                try { 
                    await queryFulfilled;
                    dispatch(
                        apiSlice.util.updateQueryData('getCard', cardId.toString(), card => {
                            let exisitingAuxItem = card.auxiliary_items.find(data => data.id === auxiliaryItemId);
                            if (exisitingAuxItem) {
                                card.auxiliary_items.splice(card.auxiliary_items.indexOf(exisitingAuxItem),1);
                            }
                        })
                    );
                    dispatch(NotificationActions.addSuccessNotification({ message: 'Section successfully deleted.' }));
                } catch {
                    dispatch(NotificationActions.addErrorNotification({ message: 'Section could not be deleted. Please try again later.' }));
                }
            }
        }),

        addCardComment: builder.mutation({
            query: ({ card, commentData }) => ({
                url: `/cards/${card.id}/comments`,
                method: 'POST',
                body: commentData
            }),
            async onQueryStarted({ card, commentData }, {dispatch, queryFulfilled }) {
                try {
                    const { data: newComment } = await queryFulfilled;
                    dispatch (
                        apiSlice.util.updateQueryData('getCard', card.id.toString(), card => {
                            card.comments.push(newComment);
                        })
                    )
                    dispatch(NotificationActions.addSuccessNotification({ message: `New comment ${newComment.category.name} added successfully.` }));
                } catch {
                    dispatch(NotificationActions.addErrorNotification({ message: 'Could not create comment at this time. Please try again later. '}));
                }
            }
        }),

        updateCardComment: builder.mutation({
            query: ({ comment, newText, card }) => ({ 
                url: `/cards/${card.id}/comments/${comment.id}`,
                method: 'PATCH',
                body: {
                    body: newText
                }
            }),
            async onQueryStarted({ comment, newText, card }, { dispatch, queryFulfilled }) {
                try {
                    const { data: updatedComment } = await queryFulfilled;
                    dispatch (
                        apiSlice.util.updateQueryData('getCard', card.id.toString(), existingCard => {
                            let existingComment = existingCard.comments.find(data => data.id === comment.id);
                            if (existingComment) {
                                Object.assign(existingComment, updatedComment);
                            }
                        })
                    );
                    dispatch(NotificationActions.addSuccessNotification({ message: `Comment ${updatedComment.category.name} updated successfully.` }));
                } catch {
                    dispatch(NotificationActions.addErrorNotification({ message: 'Could not update comment at this time. Please try again later.' }));
                }
            }
        }),

        deleteCardComment: builder.mutation({
            query: ({cardId,commentId}) =>({
                url: `cards/${cardId}/comments/${commentId}`,
                method: 'DELETE'
            }),
            async onQueryStarted({cardId,commentId}, {dispatch, queryFulfilled}){
                try { 
                    await queryFulfilled;
                    dispatch(   
                        apiSlice.util.updateQueryData('getCard', cardId.toString(), card => {
                            let existingComment = card.comments.find(data => data.id === commentId);
                            if (existingComment) {
                                card.comments.splice(card.comments.indexOf(existingComment),1);
                            }
                        })
                    );
                    dispatch(NotificationActions.addSuccessNotification({ message: 'Comment successfully deleted.' }));
                } catch {
                    dispatch(NotificationActions.addErrorNotification({ message: 'Could not delete comment at this time. Please try again later.' }));
                }
            }
        }),

        addItemToCard: builder.mutation({
            query: ({ card, item, requestData }) => ({
                url: `/cards/${card.id}/items/${item.id}`,
                method: 'POST',
                body: requestData
            }),
            async onQueryStarted({ card, item, requestData }, { dispatch, queryFulfilled }) {
                try {
                    const { data: updatedCard } = await queryFulfilled;
                    dispatch (
                        apiSlice.util.updateQueryData('getCard', card.id.toString(), existingCard => {
                            Object.assign(existingCard, updatedCard);
                        })
                    );
                    dispatch(NotificationActions.addSuccessNotification({ message: 'Item successfully added to card.' }));
                } catch {
                    dispatch(NotificationActions.addErrorNotification({ message: 'Could not add item to card at this time. Please try again later or contact support. '}));
                }
            }
        }),

        getCardOwner :builder.query({
            query: (cardOwnerId) => `/cardOwners/${cardOwnerId}`,
            providesTags: (result, error, arg) => [{ type: 'CardOwner', id: arg }]
        }),

        getCardOwnersByPage: builder.query({
            query: ({ hospitalId, page }) => `/hospitals/${hospitalId}/cardOwners?page=${page}`,
            providesTags: (result = [], error, arg) =>
                result.data ? 
                    [
                        ...result.data.map(({ id }) => ({ type: 'CardOwner', id })),
                        { type: 'CardOwner', id: 'PARTIAL-LIST' }
                    ] : 
                    [{ type: 'CardOwner', id: 'PARTIAL-LIST' }],
            async onQueryStarted({}, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch {
                    dispatch(NotificationActions.addErrorNotification({ message: 'Could not load physicians at this time. Please try again later.' }));
                }
            }
        }),

        getCardOwners: builder.query({
            query: (hospitalId) => `/hospitals/${hospitalId}/cardOwners`,
            providesTags: (result = [], error, arg) =>
                result ?
                    [
                        ...result.map(({ id }) => ({ type: 'CardOwner', id })),
                        { type: 'CardOwner', id: 'LIST' }
                    ] :
                    [{ type: 'CardOwner', id: 'LIST' }],
            async onQueryStarted({}, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch {
                    dispatch(NotificationActions.addErrorNotification({ message: 'Could not load physicians at this time. Please try again later.' }));
                }
            }
        }),

        createCardOwner: builder.mutation({
            query: ({ hospitalId, firstName, lastName, specialty, externalId, phoneNumber }) => ({
                url: `/hospitals/${hospitalId}/cardOwners`,
                method: 'POST',
                body: {
                    first_name: firstName,
                    last_name: lastName,
                    name: firstName + ' ' + lastName,
                    specialty: specialty,
                    external_id : externalId,
                    phone_number: phoneNumber
                }
            }),
            async onQueryStarted({ hospitalId }, { dispatch, queryFulfilled }) {
                try {
                    const { data: newOwner } = await queryFulfilled;
                    dispatch(NotificationActions.addSuccessNotification({ message: `New physician ${newOwner.name} created successfully.` }));
                } catch {
                    dispatch(NotificationActions.addErrorNotification({ message: 'Failed to create physician. Please try again later.' }));
                }
            },
            invalidatesTags: ['CardOwner']
        }),

        updateCardOwner: builder.mutation({
            query: ({ cardOwner, firstName, lastName, specialty, externalId, phoneNumber, doNotText }) => ({
                url: `/cardOwners/${cardOwner.id}`,
                method: 'PATCH',
                body: {
                    first_name: firstName,
                    last_name: lastName,
                    specialty: specialty,
                    external_id: externalId,
                    phone_number: phoneNumber,
                    do_not_text: doNotText
                }
            }),
            async onQueryStarted({ cardOwner }, { dispatch, queryFulfilled }) {
                try {
                    const { data: updatedOwner } = await queryFulfilled;
                    dispatch(
                        apiSlice.util.updateQueryData('getCardOwners', cardOwner.hospital.id, draft => {
                            let existingCardOwner = draft.find((u) => cardOwner.id === u.id);
                            if (existingCardOwner) {
                                Object.assign(existingCardOwner, updatedOwner);
                            }
                        })
                    )
                    dispatch(NotificationActions.addSuccessNotification({ message: 'Physician successfully updated.' }));
                } catch {
                    dispatch(NotificationActions.addErrorNotification({ message: 'Physician failed to update. Please try again later.' }));
                }
            },
            invalidatesTags: ['CardOwner']
        }),

        deleteCardOwner: builder.mutation({
            query: ({ cardOwner }) => ({
                url: `/cardOwners/${cardOwner.id}`,
                method: 'DELETE',
            }),
            async onQueryStarted({ cardOwner }, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    dispatch(NotificationActions.addSuccessNotification({ message: `Physician ${cardOwner.name} deleted successfully.` }));
                } catch {
                    dispatch(NotificationActions.addErrorNotification({ message: 'Physician failed to delete. Please try again later.' }));
                }
            },
            invalidatesTags: ['Card', 'CardOwner'] 
        }),

        getCard: builder.query({
            query:(cardID) => `/cards/${cardID}?include=recommendations`,
            providesTags: ['Card']
        }),

        getPrintedCard: builder.query({
            query: (cardId) => `/cards/${cardId}/printedCard`
        }),
        
        addAuxiliaryItemImage: builder.mutation({
            query: ({ card, formData }) => ({
                url: `/cards/${card.id}/auxiliaryItemImages`,
                method: 'POST',
                body: formData
            }),
            async onQueryStarted({ card, formData }, { dispatch, queryFulfilled }) {
                try {
                    const { data: updatedCard } = await queryFulfilled;
                    dispatch (
                        apiSlice.util.updateQueryData('getCard', card.id.toString(), existingCard => {
                            Object.assign(existingCard, updatedCard);
                        })
                    );
                    dispatch(NotificationActions.addSuccessNotification({
                        message: 'Image added successfully.'
                    }));
                } catch {
                    dispatch(NotificationActions.addErrorNotification({
                        message: 'Could not add image at this time. Please try again later.'
                    }))
                }
            }
        }),
        
        deleteAuxiliaryItemImage: builder.mutation({
            query: ({ card, imageId }) => ({
                url: `/cards/${card.id}/auxiliaryItemImages/${imageId}`,
                method: 'DELETE'
            }),
            async onQueryStarted({ card, imageId }, { dispatch, queryFulfilled }) {
                try {
                    const { data: updatedCard } = await queryFulfilled;
                    dispatch (
                        apiSlice.util.updateQueryData('getCard', card.id.toString(), existingCard => {
                            Object.assign(existingCard, updatedCard);
                        })
                    );
                    dispatch(NotificationActions.addSuccessNotification({
                        message: 'Image removed successfully.'
                    }));
                } catch {
                    dispatch(NotificationActions.addErrorNotification({
                        message: 'Could not remove image at this time. Please try again later.'
                    }))
                }
            }
        }),

        addCommentImage: builder.mutation({
            query: ({ card, formData }) => ({
                url: `/cards/${card.id}/commentImages`,
                method: 'POST',
                body: formData
            }),
            async onQueryStarted({ card, formData }, { dispatch, queryFulfilled }) {
                try {
                    const { data: updatedCard } = await queryFulfilled;
                    dispatch (
                        apiSlice.util.updateQueryData('getCard', card.id.toString(), existingCard => {
                            Object.assign(existingCard, updatedCard);
                        })
                    );
                    dispatch(NotificationActions.addSuccessNotification({
                        message: 'Image added successfully.'
                    }));
                } catch {
                    dispatch(NotificationActions.addErrorNotification({
                        message: 'Could not add image at this time. Please try again later.'
                    }))
                }
            }
        }),

        deleteCommentImage: builder.mutation({
            query: ({ card, imageId }) => ({
                url: `/cards/${card.id}/commentImages/${imageId}`,
                method: 'DELETE'
            }),
            async onQueryStarted({ card, imageId }, { dispatch, queryFulfilled }) {
                try {
                    const { data: updatedCard } = await queryFulfilled;
                    dispatch (
                        apiSlice.util.updateQueryData('getCard', card.id.toString(), existingCard => {
                            Object.assign(existingCard, updatedCard);
                        })
                    );
                    dispatch(NotificationActions.addSuccessNotification({
                        message: 'Image removed successfully.'
                    }));
                } catch {
                    dispatch(NotificationActions.addErrorNotification({
                        message: 'Could not remove image at this time. Please try again later.'
                    }))
                }
            }
        }),

        searchCards: builder.query({
            query: ({ hospitalId, searchTerm, filter, page }) => ({
                url: `/hospitals/${hospitalId}/cardSearch${page ? `?page=${page}` : ''}${searchTerm ? `&search=${searchTerm}` : ''}${filter ? `&physician=${filter}` : ''}`,
            }),
            providesTags: (result = [], error, arg) => result.data 
                ? [
                    ...result.data.map(({ id }) => ({ type: 'Card', id })),
                    { type: 'Card', id: 'PARTIAL-LIST' },
                ]
                : [{ type: 'Card', id: 'PARTIAL-LIST' }],
        }),

        searchCardOwners: builder.query({
            query: ({ hospitalId, searchTerm, filter, page }) => ({
                url: `/hospitals/${hospitalId}/cardOwnerSearch${page ? `?page=${page}` : ''}${searchTerm ? `&search=${searchTerm}` : ''}${filter ? `&specialty=${filter}` : ''}`,
            }),
            providesTags: (result = [], error, arg) => result.data
                ? [
                    ...result.data.map(({ id }) => ({ type: 'CardOwner', id })),
                    { type: 'CardOwner', id: 'PARTIAL-LIST' },
                ]
                : [{ type: 'CardOwner', id: 'PARTIAL-LIST' }],
        }),

        swapItems: builder.mutation({
            query: ({ card, item1, item2, open_quantity, hold_quantity }) => ({
                url: `/cards/${card.id}/swaps`,
                method: 'POST',
                body: {
                    item_id: item1.id,
                    swapping_item_id: item2.id,
                    open_quantity: open_quantity,
                    hold_quantity: hold_quantity
                }
            }),
            async onQueryStarted({ card, item1, item2, open_quantity, hold_quantity }, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    dispatch (
                        apiSlice.util.updateQueryData('getCard', card.id.toString(), existingCard => {
                            let replacedItem = existingCard.pick_list[item1.category].find(data => data.id === item1.id);
                            if (replacedItem) {
                                Object.assign(replacedItem, item2); 
                                replacedItem.open_quantity = open_quantity;
                                replacedItem.hold_quantity = hold_quantity;
                            }
                        })
                    );
                    dispatch(NotificationActions.addSuccessNotification({
                        message: 'Items swapped successfully.'
                    }));
                } catch {
                    dispatch(NotificationActions.addErrorNotification({
                        message: 'Could not swap items at this time. Please try again later.'
                    }))
                }
            }
        }),

        getCardsByCardOwner: builder.query({
            query: ({ hospitalId, cardOwnerId }) => `/hospitals/${hospitalId}/cardOwners/${cardOwnerId}/cards`,
            providesTags: (result = [], error, arg) => result.data 
                ? [
                    ...result.data.map(({ id }) => ({ type: 'Card', id })),
                    { type: 'Card', id: 'PARTIAL-LIST' },
                ]
                : [{ type: 'Card', id: 'PARTIAL-LIST' }],
        }),
    })
});

export const {
    useGetCardsForHospitalQuery,
    useCreateCardMutation,
    useUpdateCardMutation,
    useDeleteCardMutation,
    useAddAuxiliaryItemMutation,
    useUpdateAuxiliaryItemMutation,
    useDeleteAuxiliaryItemMutation,
    useDeleteCardCommentMutation,
    useAddCardCommentMutation,
    useUpdateCardCommentMutation,
    useAddItemToCardMutation,
    useGetCardOwnerQuery,
    useGetCardOwnersByPageQuery,
    useGetCardOwnersQuery,
    useDeleteCardOwnerMutation,
    useCreateCardOwnerMutation,
    useUpdateCardOwnerMutation,
    useGetCardQuery,
    useGetPrintedCardQuery,
    useAddAuxiliaryItemImageMutation,
    useDeleteAuxiliaryItemImageMutation,
    useAddCommentImageMutation,
    useDeleteCommentImageMutation,
    useSearchCardsQuery,
    useSearchCardOwnersQuery,
    useSwapItemsMutation,
    useGetCardsByCardOwnerQuery
} = cardSlice;